@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Inter", "Cabin", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  background: #f5f5f6;
  position: relative;
}

#root {
  /* position: relative; */
  min-height: 100vh;
}

.app_wrapper {
  min-height: 100vh;
  height: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* @media (min-width: 768px) {
  .container {
    max-width: 850px;
  }
} */

@media ((min-width: 1400px)) {
  .container {
    max-width: 1320px;
  }
}

/* button */
.btn {
  text-align: center;
  font-size: 1rem;
  padding: 0.9rem 2.5rem;
  font-weight: 500;
  border-width: 2px;
}

.pc_btn_primary {
  color: #fff;
  background: #ff5349;
  border-color: rgb(255, 83, 73);
}

.pc_btn_primary :hover {
  background-color: rgba(255, 83, 73, 0.7) !important;
}

.pc_btn_primary_outline {
  color: #ff5349;
  background: #fff;
  border-color: #ff5349;
}

/* Form and Input */
.pc_form__group {
  margin-bottom: 1.875rem;
}

.pc_form__lebel {
  font-size: 1rem;
  font-weight: 400;
  color: #454242;
}

input[type="checkbox"] {
  content: " ";
}

input[type="checkbox"]::before {
  color: #00ae97 !important;
  background-color: #00ae97 !important;
}

/* Stripe */

.DemoPickerWrapper {
  padding: 0 12px;
  font-family: "Source Code Pro", monospace;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background: white;
  margin: 24px 0 48px;
  width: 100%;
}

.DemoPicker {
  font-size: 18px;
  border-radius: 3px;
  background-color: white;
  height: 48px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  border: 0;
  width: 100%;
  color: #6772e5;
  outline: none;
  background: transparent;

  -webkit-appearance: none;
}

.DemoWrapper {
  margin: 0 auto;
  max-width: 500px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.Demo {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40%;
}

.Stripe__Label {
  color: #150200;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.75rem;
}

.Stripe__Label::after {
  content: "*";
  color: #ff5349;
  font-size: 0.875rem;
}

.Stripe__button {
  border-radius: 5px;
  background: #00ae97;
  color: #ffffff;
  padding: 1rem;
  width: 100%;
  font-size: 1.15rem;
  font-weight: 600;
  text-align: center;
  outline: 0;
  border: 0;
}

.StripeElement {
  border-radius: 5px;
  border: 1.5px solid #c4c4c4;
  background: #fff;
  display: block;
  padding: 1rem 1.5rem;
  font-size: 1em;
  font-family: "Poppins", monospace;
  outline: 0;
}

.StripeElement--focus {
  border: 1.5px solid #00ae97;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

.social_link_group svg path {
  fill: #0f0f10;
}

.show_menu {
  opacity: 1 !important;
  visibility: visible !important;
  z-index: 999;
  height: 400px;
}

.ladybug_bg_w {
  background-color: #ffffff;
  background-image: url("./assets/images/ladybug-r-t.svg"),
    url("./assets/images/Ladybug-l-b.svg");
  background-position: top right, bottom left;
  background-size: 660px 760px, 660px 760px;
  background-repeat: no-repeat, no-repeat;
}

.qtyHolder svg path {
  stroke: #ed2115;
}

.qtyHolder .Mui-disabled svg path {
  stroke: rgb(173, 174, 179);
}

a:hover {
  color: #0f0f10;
}
